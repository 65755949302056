<template>
    <div>
        这是一个订单提交成功的页面
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>

</style>